@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");
@import url("https://fonts.googleapis.com/css?family=VT323");
@import url('https://fonts.googleapis.com/css2?family=YourFontName&display=swap'); 
@font-face {
  font-family: 'Pixels';  
  src: 
      url('./assets/fonts/Pixels.ttf') format('truetype');  
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* This ensures padding and border are included in the total width/height */
}
.background {
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff8080,
    #00ff00,
    #80ff80,
    #0000ff,
    #8080ff
  );
  
  background-image: url('./assets/background/Gnomes.gif'); 
  background-repeat: no-repeat;
  background-size: cover;  /* this ensures that the image will cover the viewport */
  background-position: center bottom;
  
  color: brown;
  font-family: "Press Start 2P", "VT323";
  animation: gradientAnimation 15s ease infinite;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.8));
  height: 100vh;  /* fills the height of the viewport */
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}


.cancel-button {
  position: absolute;
  margin-top: -40px;
  right: -40px;
  font-size: 20px;
  padding: 10px 10px;
  background-color: #ff5252;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-family: inherit;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: #ff3333;
  }
}
.cancel-button :hover {
  color: #ff3333;
}

.deposit-button {
  position: absolute;
  margin-top: 140px;
  right: -40px;
  font-size: 20px;
  padding: 10px 10px;
  background-color: #ff5252;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-family: inherit;
  transition: background-color 0.2s ease;
  
  @media (max-width: 1050px) {
    margin-top: 150px; /* Adjust as needed for smaller screens */
  }
  @media (max-width: 875px) {
    margin-top: 200px; /* Adjust as needed for smaller screens */
  }
  @media (max-width: 700px) {
    margin-top: 240px; /* Adjust as needed for smaller screens */
  }
  
  &:hover {
    background-color: #ff3333;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

* {
  box-sizing: border-box;
}

.footer {
  height: 35px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.9), black); /* Approximate exponential fade */
  color: white;
  text-align: center;
  font-family: 'Pixels'; 
  font-size: 24px;
  padding-top: 0px;
  line-height: 35px; 
}


/* Top Footer */
.top-footer {
  height: 35px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)); /* Fades into the background */
  backdrop-filter: blur(5px); /* Adjust the blur radius as needed */
  color: white;
  text-align: center;
  font-family: 'Pixels'; 
  font-size: 36px;
  padding-top: 0px;
  line-height: 20px;
  z-index: 150;

  transition: color 0.3s; 

@media (max-width: 480px) {
  .footer-item:not(:first-child) {
      display: none; /* This hides all footer items on mobile except the first one */
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .footer-item {
      display: none; /* Hide all footer items on tablet */
  }
  .footer-item:first-child,
  .footer-item:nth-child(2) {
      display: inline; /* Show only the first and second items on tablet */
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .footer-item {
      display: none; /* Hide all footer items on small laptop screens */
  }
  .footer-item:first-child,
  .footer-item:nth-child(2),
  .footer-item:nth-child(3) {
      display: inline; /* Show the first, second, and third items on small laptop screens */
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .footer-item {
      display: none; /* Hide all footer items on large screens and desktops */
  }
  .footer-item:first-child,
  .footer-item:nth-child(2),
  .footer-item:nth-child(3),
  .footer-item:nth-child(4) {
      display: inline; /* Show the first four items on large screens and desktops */
  }
}
@media (min-width: 1201px) {
  .footer-item {
      display: inline; /* Show all footer items on TV and extra large screens */
  }
}
}

.footer-item:hover {
  color: #FF5733; /* Change the color to your desired hover color */
  cursor: pointer; 
}

.overlay {
  opacity: 1;
  width: 70%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}


.button-container {
  position: absolute;
  right: 25px;
  top: 25px;
}

.logo-container {
  transform: scale(0.85);
  position: absolute;
  left: 5vw;
  top: 5vh;
  transition: top 0.2s ease;
}

@media (max-width: 768px) {
  .logo-container img {
    width: 10vw;
    height: 10vh;
  }
}

.my-input-style {
  text-align: center;
  font-size: 20px;
}

input[type="number"] {
  appearance: textfield;
}
.OpenSeaLogoContainer {
  position: absolute;
  top: 30px; /* Adjust the vertical position as needed */
  left: var(--open-sea-left); /* Reference the custom property */
  cursor: pointer;

}

/* Add this CSS to your application's global styles or a CSS file */

/* Overlay style to darken the background */
.queuemodal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal content style */
.queuemodal-content {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  width: 400px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.blinking {
  animation: blink 1s infinite;
}


/* Close button style */
.queuemodal-content button {
  background-color: #ff5252;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

/* Table style */
.queuemodal-content table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

/* Table header style */
.queuemodal-content th {
  background-color: #f2f2f2;
  text-align: left;
  padding: 10px;
}

/* Table row style */
.queuemodal-content tr {
  border-bottom: 1px solid #ddd;
}

/* Table cell style */
.queuemodal-content td {
  padding: 10px;
}
